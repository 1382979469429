// import App from '@components/misc/Winners.vue'
import { generateApplication } from '@helpers/generate_component.js';
import { defineAsyncComponent, markRaw } from 'vue';
import Loader from "@mints-components/loaders/PageLoader.vue";
import ServerError from "@views/settings/index/errors/ServerError.vue";
// import winner from "@components/misc/Winner.vue";


contentData.forEach(async element => {
  // Importar el componente Vue de forma dinámica
  const componentPromise = markRaw(defineAsyncComponent({
    loader: () =>
        import (`../../components/misc/${capitalize(element.extra_params.template)}.vue`),
    loadingComponent: Loader /* shows while loading */ ,
    errorComponent: ServerError /* shows if there's an error */ ,
    delay: 10 /* delay in ms before showing loading component */ ,
    timeout: 30000 /* timeout after this many ms */ ,
  }));

  
  generateApplication(componentPromise, `#${element.extra_params.sectionIdentifier}-entry`, { props: { content: element } });
});


function capitalize(text) {
  const firstLetter = text.charAt(0);
  const rest = text.slice(1);
  return firstLetter.toUpperCase() + rest;
}
